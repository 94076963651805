export default {
  global: {
    elevation: 0,
  },
  VBtn: {
    color: "primary",
    rounded: "lg",
    size: "small",
    // height: 45,
  },
  VBtnAlt: {
    color: "primary",
    rounded: "sm",
    height: 20,
    variant: "outlined",
    class: "mt-6",
    size: "small",
  },
  VTooltip: {
    location: "top",
  },
  VCheckbox: {
    color: "primary",
    density: "comfortable",
    hideDetails: "auto",
    class: "mt-5",
    density: "compact",
    size: "small"
  },
  VRadioGroup: {
    color: "primary",
    density: "comfortable",
    hideDetails: "auto",
    class: "mt-5",
    density: "compact"
  },
  VSheet: {
    elevation: 0,
    rounded: "lg",
    border: "0",
    class: "pt-5 px-5 pb-5",
  },
  VTextField: {
    color: "primary",
    variant: "outlined",
    rounded: "sm",
    hideDetails: "auto",
    bgColor: "surface",
    class: "mt-5",
    density: "compact"
  },
  VSelect: {
    color: "primary",
    variant: "outlined",
    rounded: "sm",
    hideDetails: "auto",
    bgColor: "surface",
    class: "mt-5",
    density: "compact"
  },
  VSwitch: {
    color: "primary",
    variant: "outlined",
    hideDetails: "auto",
    class: "mt-5",
  },
  VAutocomplete: {
    color: "primary",
    variant: "outlined",
    rounded: "sm",
    bgColor: "surface",
    hideDetails: "auto",
    class: "mt-5",
  },
  VCombobox: {
    color: "primary",
    variant: "outlined",
    rounded: "sm",
    bgColor: "surface",
    hideDetails: "auto",
    class: "mt-5",
    density: "compact"
  },
  VTextarea: {
    variant: "outlined",
    color: "primary",
    hideDetails: "auto",
    class: "mt-5",
    density: "compact"
  },
  VCard: {
    elevation: 4,
    class: "pa-2",
    VCardTitle: {
      class: "text-body-2 text-uppercase",
      weight: "bold",
    },
    VCardSubtitle: {
      class: "text-body-2 text-uppercase text-lighttext",
    },
  },
  VCardDevice: {
    elevation: 0,
    color: "primary-lighten-5",
    rounded: "xl",
    class: "pa-4",
    VCardTitle: {
      class: "pa-0 font-weight-medium mb-1 text-uppercase",
      style: "font-size: 1.125rem",
    },
    VCardItem: {
      class: "pa-0",
    },
    VCardText: {
      class: "pa-0 text-caption",
    },
    VCardSubtitle: {
      class: "pa-0 text-h6 font-weight-regular ml-0",
      style: "opacity: 1",
    },
    VAvatar: {
      size: "51",
      class: "mr-3 pa-1",
    },
  },

  VTabs: {
    color: "darktext",
    bgColor: "transparent",
    class: "border-0",
    density: "compact",
    VBtn: {
      rounded: "0",
      class: "text-h6 font-wweight-regular",
      size: "small",
    },
  },
  VDivider: {
    color: "primary",
    thickness: 1,
    class: "border-opacity-25 mb-4 mt-0 pt-0",
  },
  VAlert: {
    variant: "tonal",
    background: "surface",
    density: "compact",
  },
  VAlertError: {
    color: "error",
    variant: "tonal",
    type: "error",
  },
  VAlertSuccess: {
    color: "success",
    variant: "tonal",
    type: "success",
  },
  VChip: {
    color: "primary",
    rounded: "lg",
  },
  VChipInfo: {
    color: "blue",
  },
  VChipError: {
    color: "error-dark",
  },
  VChipSuccess: {
    color: "info",
  },
};
