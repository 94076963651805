<template>

  <!-- Debug slot -->
  <span class="component-name" v-if="$store.state.ui.debug && mustShowComponent" @mouseover="debugHover" @mouseleave="debugHoverLeave">
      {{ debugModuleName($store.getters.getComponent(elem.element.module)) }}
      <v-tooltip
        activator="parent"
        location="start"
        scroll-strategy="reposition"
      >
      <pre style="font-size:0.8em" @click="copyContent(elem.element)">
{{ elem.element.params?.__yaml_filepath ? '==> ' + elem.element.params?.__yaml_filepath : ''}}
==> {{$store.getters.getComponentFilePath(elem.element.module)}}
{{"\n"}}
{{ elem.element }}
      </pre>
    </v-tooltip>
  </span>
  
  <component
    v-if              = "mustShowComponent"
    :is               = "$store.getters.getComponent(elem.element.module)"
    :content          = "elem.element.content ?? []"
    :vuetifyParams    = "elem.element.vuetifyParams ?? {}"
    :subVuetifyParams = "elem.element.subVuetifyParams ?? {}"
    :params           = "elem.element.params ?? {}"
    :context          = "elem.element.context ?? {}"
    :class            = "debug_hover ? 'debug_actif' : ''"
  />
</template>

<script src="./ContentTypeElement.js"></script>

<style scoped>
.component-name {
  z-index: 1000;
  color:blue;
  font-style:italic;
  font-size:0.8em;
  line-height: 1em;
  cursor: help;
}
.debug_actif {
  border: 1px dashed red;
  background-color: rgba(255, 0, 0, 0.1);
}
.v-overlay__content{
  opacity: 0.8 !important;
}
</style>