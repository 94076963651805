import ApiService from '@shared/services/api'


export default class ConfigService extends ApiService {

    static getEnvConfig() {
        return {
            customerSystemName: process.env.CUSTOMER_SYSTEM_NAME,
            boUrl: process.env.BO_URL,
            defaultLang: process.env.DEFAULT_LANG,
            appEnv: process.env.APP_ENV,
        }
    }

    static async getBOConfig(appName) {
        let url = '/fo/config/' + appName;
        let response = await super.get(url);
        return response.data;
    }

    static async getI18nConfig(appName, lang) {
        try {
            let translations = await super.get('/fo/' + appName + '/translations/' + lang)
            return translations.data
        }
        catch (error) {
            console.log(error);
            return false;
        }
    }
}