<template>
  <v-app>
    <v-layout class="rounded rounded-md">

      <Menu
        v-if="!('showMenu' in layout) || layout.showMenu === true"
        v-model:drawer="drawer"
      />

      <v-btn
        elevation="4"
        color="primary"
        rounded="xl"
        size="small"
        class="navigation-menu-cta--wrapper d-block d-lg-none"
        @click.stop="drawer = !drawer"
      >
        <MenuIcon size="2" />
        <span class="ml-1">Menu</span>
      </v-btn>

      <v-main>
        <v-container fluid class="fill-height py-6">
          <v-row id="main-container" class="fill-height">
            <v-row v-if="$store.state.ui.debug" class="ma-0 pa-0">
                  <v-col>
                    <span class="text-caption">
                      {{ __yaml_filepath }}
                    </span>
                  </v-col>
            </v-row>
            <v-col cols="12" class="pt-0 pb-0 ga-6">
              <div
                v-if="title"
                class="d-flex justify-space-between align-center"
              >
                <v-row>
                  <v-col>
                    <h1 class="my-4 text-h1">{{ $t(title) }}</h1>
                  </v-col>
                </v-row>
              </div>
              <ContentType :content="content" />
            </v-col>
          </v-row>

          <v-dialog
            v-model="modalOpen"
            :content-class="'modal-' + $store.getters.getModalParam('type')"
            :fullscreen="
              $store.getters.getModalParam('type') == 'fullscreen'
                ? true
                : false
            "
            :transition="
              getModalTransition($store.getters.getModalParam('type'))
            "
            v-bind="$store.getters.getModalParam('vuetifyParams')"
          >
            <v-sheet
              :class="
                !['modal', 'large'].includes($store.getters.getModalParam('type'))
                  ? 'rounded-t-0 rounded-b-0'
                  : ''
              "
              v-bind="$store.getters.getModalParam('subVuetifyParams')"
            >
              <v-icon @click="$store.dispatch('closeModal')" class="btn-close" v-if="$store.getters.getModalParam('vuetifyParams')?.persistent !== true">mdi-close</v-icon>
              <h1 v-if="$store.getters.getModalParam('title')">
                {{ $t($store.getters.getModalParam("title")) }}
              </h1>
              <ContentType :content="$store.getters.getModalParam('content')" />
            </v-sheet>
          </v-dialog>

          <v-dialog
            v-model="modal2Open"
            :content-class="'modal-' + $store.getters.getModalParam('type2')"
            :fullscreen="
              $store.getters.getModalParam('type2') == 'fullscreen'
                ? true
                : false
            "
            :transition="
              getModalTransition($store.getters.getModalParam('type2'))
            "
          >
            <v-sheet
              :class="
                !['modal', 'large'].includes($store.getters.getModalParam('type2'))
                  ? 'rounded-t-0 rounded-b-0'
                  : ''
              "
            >
              <v-icon @click="$store.dispatch('closeModal2')" class="btn-close" v-if="$store.getters.getModalParam('vuetifyParams2')?.persistent !== true">mdi-close</v-icon>
              <h1 v-if="$store.getters.getModalParam('title2')">
                {{ $t($store.getters.getModalParam("title2")) }}
              </h1>
              <ContentType
                :content="$store.getters.getModalParam('content2')"
              />
            </v-sheet>
          </v-dialog>
          
        </v-container>
      </v-main>
    </v-layout>

    <!-- <modal
    :open="$store.getters.getModalParam('open')"
    :panel="$store.getters.getModalParam('panel')"
    :top="$store.getters.getModalParam('top')"
    :right="$store.getters.getModalParam('right')"
    :bottom="$store.getters.getModalParam('bottom')"
    :left="$store.getters.getModalParam('left')"
    :full="$store.getters.getModalParam('full')"
    :layout="$store.getters.getModalParam('layout')"
    :showClose="$store.getters.getModalParam('showClose')"
  > 
    <v-app>
      <h1 v-if="$store.getters.getModalParam('title')">{{ $store.getters.getModalParam('title') }}</h1>
      <ContentType :content="$store.getters.getModalParam('content')" />
    </v-app>
  </modal> -->
  </v-app>
</template>

<script>
import Menu from "@shared/components/Menu.vue";
import ContentType from "@shared/components/ContentType.vue";
import MenuIcon from "@shared/components/icons/MenuIcon.vue";
import autoDisconnect from "@shared/mixins/auto_disconnect";

export default {
  name: "Layout",
  mixins: [autoDisconnect],
  data() {
    return {
      drawer: window.matchMedia("(min-width: 1280px)").matches,
      authorizedFrames: [
        'https://demo.docusign.net',
        this.$store.getters.getEnvConfigValue('boUrl')
      ]
    };
  },
  methods: {
    getModalTransition(modalType) {
      switch (modalType) {
        case "panel-right":
          return "dialog-right-transition";

        case "panel-left":
          return "dialog-left-transition";

        default:
          return "dialog-transition";
      }
    },
    updateDrawer() {
      this.drawer = window.matchMedia("(min-width: 1280px)").matches;
    },
  },
  created() {
    window.addEventListener("resize", this.updateDrawer);
    // Listen to messages from any BO frame.
    window.addEventListener('message', event => {
    // IMPORTANT: check the origin of the data!
    if (this.authorizedFrames.includes(event.origin)) {
      console.log('Message received from authorized frame ('+event.origin+'):', event.data);
      const data = event.data.split(',');
      const type = data[0];
      const args = data.slice(1);
      switch (type) {
        case 'closeModal':
          this.$store.dispatch('closeModal');
          break;

        case 'closeModal2':
          this.$store.dispatch('closeModal2');
          break;

        case 'afterSubmitSuccess':
          this.$store.dispatch('closeModal');
          this.$store.dispatch('form/afterSubmitSuccess', args[0]);
          break;
      }
    } else {
        // !!! CAREFUL !!!
        // Do NOT use this else branch.
        // Any message here is from an iframe we do not control, we must ignore it.
        return;
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateDrawer);
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    layout: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Array,
      default: () => [],
    },
    routingParams: {
      type: Object,
      default: () => ({}),
    },
    __yaml_filepath: {
      type: String,
      default: "",
    },
  },
  watch: {},
  computed: {
    modalOpen: {
      get: function () {
        return this.$store.getters.getModalParam("open");
      },
      set: function (newValue) {
        if (newValue == false) {
          this.$store.dispatch("closeModal");
        }
      },
    },
    modal2Open: {
      get: function () {
        return this.$store.getters.getModalParam("open2");
      },
      set: function (newValue) {
        if (newValue == false) {
          this.$store.dispatch("closeModal2");
        }
      },
    },
    getClass() {
      return this.layout.class;
    },
  },
  components: {
    Menu,
    ContentType,
    MenuIcon,
  },
};
</script>

<style lang="scss">
.navigation-menu-cta--wrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 999;
}
</style>
