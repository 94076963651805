import AuthService from "@shared/services/auth";
import dayjs from 'dayjs';
import { merge } from 'lodash';

const AUTH_SET_LOGIN_FLOW = 'AUTH_SET_LOGIN_FLOW';
const AUTH_SET_BEFORE_AUTH_PARAMS = 'AUTH_SET_BEFORE_AUTH_PARAMS';
const AUTH_CONNECT = 'AUTH_CONNECT';
const AUTH_DISCONNECT = 'AUTH_DISCONNECT';
const AUTH_SET_PROFILE_DATA = 'AUTH_SET_PROFILE_DATA';
const AUTH_SET_ALERT_DATA = 'AUTH_SET_ALERT_DATA';
const AUTH_SET_ALERT_LOADING = 'AUTH_SET_ALERT_LOADING';
const AUTH_SET_FORCED_LOGOUT = 'AUTH_SET_FORCED_LOGOUT';

const mutations = {
  [AUTH_SET_LOGIN_FLOW](state, flow) {
    state.login_flow = flow;
  },
  [AUTH_SET_BEFORE_AUTH_PARAMS](state, params) {
    state.before_auth_params = merge(state.before_auth_params, params);
  },
  [AUTH_CONNECT](state) {
    state.connected = true;
    // state.csrf_token = user.csrf_token;
    // state.id = user.current_user.uid;
    // state.name = user.current_user.name;
    // state.roles = user.current_user.roles;
  },
  [AUTH_DISCONNECT](state) {
    state.connected = false;
    state.uid = null;
    state.roles = [];
    state.profile = {};
    state.alerts = null;
    state.name = null;
    state.lang = null;
    // state.csrf_token = null;
    // state.id = null;
    // state.name = null;
    // state.roles = [];
  },
  [AUTH_SET_PROFILE_DATA](state, data) {
    state.lang = data.preferred_langcode;
    state.profile = data;
    state.roles = data.roles;
    state.permissions = data.permissions;
  },
  [AUTH_SET_ALERT_DATA](state, data) {
    state.alerts = data;
  },
  [AUTH_SET_ALERT_LOADING](state, loading) {
    state.alerts_loading = loading;
  },
  [AUTH_SET_FORCED_LOGOUT](state, forced_logout) {
    state.forced_logout = forced_logout;
  }
}

const state = {
  before_auth_params: {},
  login_flow: 'signin',
  connected: false,
  uid: null,
  roles: [],
  permissions: [],
  profile: {},
  alerts: null,
  name: null,
  csrf_token: null,
  lang: null,
  alerts_loading : false,
  forced_logout: false,
}

const getters = {
  userGetLoginFlow: state => state.login_flow,
  userIsConnected: state => state.connected,
  userGetLang: (state, getters) => {
    // This will be improved, with user value and local storage 
    return state.lang ? state.lang : getters.getEnvConfigValue('defaultLang');
  },
  userGetProfile: state => state.profile,
  userGetAlerts: state => state.alerts,
  userGetUuid: state => state.profile.uuid,
  userGetUid: state => state.profile.uid,
  userGetCapitals: (state) => {
    if (
      !state.profile.last_name &&
      !state.profile.first_name
    ) {
      return "";
    }
    return (
      "" +
      state.profile.first_name[0] +
      state.profile.last_name[0]
    );
  },
  userHasPermission: (state) => (permission) => {
    if (state.roles.includes('admin')) return true;
    if (state.permissions) {
      return state.permissions.includes(permission);
    }
    return false;
  },
  userGetTimezone: (state) => {
    return dayjs().format('Z');
  }
}

const actions = {
  "auth/setLoginFlow" ({ commit }, flow) {
    commit('AUTH_SET_LOGIN_FLOW', flow);
  },
  "auth/setBeforeAuthParams" ({ commit }, params) {
    commit('AUTH_SET_BEFORE_AUTH_PARAMS', params);
  },
  "auth/connect" ({ commit }) {
    commit(AUTH_CONNECT);
  },
  async "auth/login" ({ dispatch }, loginInfo) {
    return new Promise((resolve, reject) => {
      AuthService.login(loginInfo.login, loginInfo.password)
      .then(async (data) => {
        dispatch('auth/connect');
        await dispatch('auth/getProfile');
        resolve('ok');
      })
      .catch((error) => {
        reject(error);
      })
    });

  },
  async "auth/getProfile"({ commit, dispatch }, force = false) {
    return new Promise((resolve, reject) => {
      if (Object.keys(state.profile).length == 0 || force) {
        AuthService.profile()
        .then((profile) => {
          commit(AUTH_SET_PROFILE_DATA, profile);
          dispatch('auth/setLocalProfile', profile);
          resolve('ok');
        })
        .catch((error) => {
          reject(error);
        });
      }
      else {
        resolve('ok');
      }
    });
  },
  async "auth/setOrganization"({ commit }, orgId) {
    return new Promise((resolve, reject) => {
      AuthService.setOrganization(orgId)
      .then(() => {
        resolve('ok');
      })
      .catch((error) => {
        reject(error);
      });
    });
  },
  async "auth/getAlerts"({ commit, dispatch }, force = false) {
    return new Promise((resolve, reject) => {
      if (state.alerts === null || force) {
        if(!state.alerts_loading){
          commit(AUTH_SET_ALERT_LOADING, true);
          AuthService.alerts()
          .then((alerts) => {
            commit(AUTH_SET_ALERT_DATA, alerts);
            resolve('ok');
          })
          .catch((error) => {
            reject(error);
          }).finally(() => {
            commit(AUTH_SET_ALERT_LOADING, false);
          });
        }else{
          resolve('pending');
        }
      }
      else {
        resolve('ok');
      }
    });
  },
  async "auth/logout"({ commit }) {
    return new Promise((resolve) => {
      AuthService.logout()
      .then(async () => {
        commit(AUTH_DISCONNECT);
        resolve('ok');
      })
    });
  },
  async "auth/resetPassword"({ commit }, email) {
    return new Promise((resolve, reject) => {
      AuthService.resetPassword(email)
      .then( (response) => {
        resolve(response);
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "auth/changePassword"({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      AuthService.changePassword(data)
      .then(async () => {
        dispatch('auth/getProfile', true).then(() => {
          resolve('ok');
        });
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  async "auth/updateUser"({ dispatch, state, getters }, data) {
    return new Promise((resolve, reject) => {
      let userType = getters.getBaseConfigValue('targetUserType') == 'patient' ? 'patient' : 'user';
      AuthService.updateUser(userType, state.profile.uuid, data)
      .then(async () => {
        dispatch('auth/getProfile', true).then(() => {
          resolve('ok');
        });
      })
      .catch((e) => {
        reject(e)
      })
    });
  },
  // Special action, does not act on the store but on the localStorage.
  async "auth/setLocalProfile"({ getters }, profile) {
    if (getters.getBaseConfigValue('targetUserType') == profile._type) {
      let known_accounts = localStorage.getItem('known_accounts');
      if (known_accounts) {
        known_accounts = JSON.parse(known_accounts);
        if (!known_accounts.filter((account) => { return account.login == profile.login}).length) {

          known_accounts.push(profile);
          localStorage.setItem('known_accounts', JSON.stringify(known_accounts));
        }
      }
      else {
        localStorage.setItem('known_accounts', JSON.stringify([profile]));
      }
    }
  },
  async "auth/removeLocalProfile"({ getters}, profile) {
    if (getters.getBaseConfigValue('targetUserType') == profile._type) {
      let known_accounts = localStorage.getItem('known_accounts');
      if (known_accounts) {
        known_accounts = JSON.parse(known_accounts);
        known_accounts = known_accounts.filter((account) => { return account.login != profile.login});
        localStorage.setItem('known_accounts', JSON.stringify(known_accounts));
      }
    }
  },
  "auth/setForcedLogout"({ commit }, forced_logout) {
    commit(AUTH_SET_FORCED_LOGOUT, forced_logout);
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}