// import { createRouter, createMemoryHistory } from 'vue-router'
import { createRouter, createWebHistory } from "vue-router";
import store from "@shared/store";
import AuthService from "@shared/services/auth";
import handlers from "@shared/mixins/handlers";
import { cloneDeep } from "lodash";
import { get } from "lodash";
import ContentType from "@shared/components/ContentType.vue";

function init(layout) {
  const routes = [
    {
      path: "/welcome",
      name: "welcome",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Welcome.vue"),
    },
    {
      path: "/accounts",
      name: "accounts",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Accounts.vue"),
    },
    {
      path: "/signin",
      name: "signin",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Signin.vue"),
    },
    {
      path: "/pre-signup",
      name: "pre-signup",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_PreSignup.vue"),
    },
    {
      path: "/lookup",
      name: "lookup",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Lookup.vue"),
    },
    {
      path: "/signup",
      name: "signup",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_Signup.vue"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: { disconnected: true },
      component: () => import("@shared/components/auth/_ResetPassword.vue"),
    },
    {
      path: "/change-password",
      name: "force-change-password",
      meta: { title: "change_password.title", disconnected: false },
      component: () =>
        import("@shared/components/auth/_ForceChangePassword.vue"),
    },
    {
      path: "/select-org",
      name: "select-org",
      meta: { title: "select_org.title", disconnected: false },
      component: () => import("@shared/components/auth/_SelectOrg.vue"),
    },
    {
      path: "/no-access",
      name: "no-access",
      meta: { title: "no_access.title", disconnected: false },
      component: layout,
      props: {
        content: [
          {
            type: "module",
            module: "auth.no-access",
            title: "no_access.title",
          },
        ],
        layout: {
          showMenu: false,
        },
      },
    },
  ];

  const getRouteConfigFromYaml = function(element, parent = true) {
    return {
      path: element.path,
      name: element.code,
      redirect: element.redirect != undefined ? element.redirect : false,
      component: parent ? layout : ContentType,
      props: {
        title: element.title,
        content: element.content,
        layout: parent ? element.layout : undefined,
        headerBar: parent ? element.headerBar : undefined,
        routingParams: element.routingParams,
        __yaml_filepath: element.__yaml_filepath,
      },
      meta: {
        title: element.title,
        disconnected:
          element.disconnected && element.disconnected === true ? true : false,
      },
    };
  }

  let homePageFound = false;
  store.getters.getBORoutes.forEach((element) => {
    if (element.path) {
      // Check if the path is a child path of an already existing path
      let pathExists = false;
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].path == '/') {
          homePageFound = true;
          continue;
        }
        // If the path we're analysing now contains ?, ignore them
        let currentRoutePath = routes[i].path.replace(/\?.*$/, '');

        if (element.path.startsWith(currentRoutePath)) {
          pathExists = true;
          // Add the path as a child of the existing path
          if (!routes[i].children) {
            routes[i].children = [];
          }
          routes[i].children.push(getRouteConfigFromYaml(element, false));
        }
      }

      if (!pathExists) {
        routes.push(getRouteConfigFromYaml(element));
      }
    }

    if (element.__yaml_error) {
      alert(element.__yaml_error);
      console.error(element.__yaml_error);
    }
  });

  if (!homePageFound) {
    // Exit execution
    throw new Error("Home page not found in routing configuration");
  }

  const router = createRouter({
    // history: createMemoryHistory(),
    history: createWebHistory(),
    routes: routes,
  });

  router.beforeEach(async (to, from) => {
    // Redirige vers le dashboard si pas de correspondance
    if (to.matched.length == 0) {
      return "/";
    }
    const newFrom = cloneDeep(from);
    delete newFrom.from;
    to.from = newFrom;

    // Change le titre de la page
    // document.title = i18n.global.t(to.meta.title)

    let access = false;
    let connected = store.getters.userIsConnected;

    // Si pas d'info sur la connexion un check si on est connecté
    if (connected !== true) {
      connected = await AuthService.status();
      if (connected) {
        await Promise.all([
          store.dispatch("auth/connect"),
          store.dispatch("auth/getProfile"),
        ]);
      }
    }

    if (store.getters.userGetProfile.password_was_reset === true) {
      return to.fullPath == "/change-password" ? true : "/change-password";
    }

    if (store.getters.userGetProfile.no_active_org === true) {
      return to.fullPath == "/no-access" ? true : "/no-access";
    }

    if (Array.isArray(store.getters.userGetProfile.must_select_org)) {
      return to.fullPath == "/select-org" ? true : "/select-org";
    }

    // calculate required permission to access this app
    const appPermissionLabel =
      "access app-" + store.getters.getBaseConfigValue("appName");
    if (connected && !store.getters.userHasPermission(appPermissionLabel)) {
      store.dispatch("auth/logout");
      return to.fullPath == "/no-access" ? true : "/no-access";
    }

    //Check si c'est une url non connecté
    if (to.meta.disconnected === true) {
      // Si c'est une URL connectée et que l'user est connecté, on renvoie l'user vers le dashboard
      return !connected ? true : "/";
    }

    // Si c'est une URL connectée et que l'user n'est pas connecté, on renvoie l'user vers la page de start
    const startUrl = store.getters.getBOConfig.auth.startUrl;
    access = connected ? true : startUrl;

    // Entity pre-loading, si il existe des "routingParams".
    await new Promise(async (resolve, reject) => {
      if (
        access &&
        "props" in to.matched[0] &&
        "default" in to.matched[0].props &&
        "routingParams" in to.matched[0].props.default &&
        to.matched[0].props.default.routingParams != undefined
      ) {
        // There can be one or several routingParams
        if (!Array.isArray(to.matched[0].props.default.routingParams)) {
          to.matched[0].props.default.routingParams = [
            to.matched[0].props.default.routingParams
          ];
        }

        for (const [i, routingParams] of to.matched[0].props.default.routingParams.entries()) {
          if ("entityType" in routingParams) {
            let queryParams = {};
            if ("entityIncludes" in routingParams) {
              queryParams.includes = cloneDeep(
                routingParams.entityIncludes
              );

              const resolveValue = function (value) {
                if (!Array.isArray(value)) {
                  if (
                    value in handlers.methods &&
                    typeof handlers.methods[value] === "function"
                  ) {
                    return handlers.methods[value]();
                  } else {
                    return value;
                  }
                }

                if (value.length == 1) return handlers.methods[value[0]]();

                if (value.length > 1) {
                  const handler = value[0];
                  const params = value[1].map((param) => {
                    if (typeof param === "object" && "handler" in param) {
                      return resolveValue([param.handler, param.params]);
                    } else {
                      return param;
                    }
                  });

                  return handlers.methods[handler](...params);
                }
              };
              const resolveFilterValues = function (filters, resolveFunc) {
                filters.forEach((filter) => {
                  if ("value" in filter) {
                    filter.value = resolveFunc(filter.value);
                  }
                  if ("filters" in filter) {
                    resolveFilterValues(filter.filters, resolveFunc);
                  }
                });
              };
              const resolveIncludeValues = function (includes, resolveFunc) {
                includes.forEach((include) => {
                  if (typeof include === "object") {
                    if ("includes" in include) {
                      resolveIncludeValues(include.includes, resolveFunc);
                    }
                    if ("filters" in include) {
                      resolveFilterValues(include.filters, resolveFunc);
                    }
                  }
                });
              };

              resolveIncludeValues(queryParams.includes, resolveValue);
            }
            let urlParameter = routingParams.urlParameter ?? 'uuid';
            let value;
            if (urlParameter in to.params && to.params[urlParameter] != undefined && to.params[urlParameter] != "") {
              value = to.params[urlParameter];
            }
            if (!value) {
              continue;
            }

            await store
              .dispatch("entityType/get", {
                _entityType: routingParams.entityType,
                _entityId: value,
                _params: queryParams,
              })
              .then(async (entityData) => {
                if (i == 0) {
                  await store
                    .dispatch("entityType/setPageContext", {
                      _entityType:
                        routingParams.entityType,
                      _entityId: value,
                      _params: queryParams,
                    })
                    .then(async () => {
                      // Recursively add context=page to all content items
                      const addContextToContent = function (content) {
                        content.forEach((item) => {
                          item.context = "page";
                          if (item.content != undefined) {
                            addContextToContent(item.content);
                          }
                        });
                      };
                      to.matched.forEach((route) => {
                        if (route.props.default.content != undefined)
                          addContextToContent(route.props.default.content);
                      });

                      console.log("entity preloading from router");
                      return;
                    });
                }
                else {
                  await store.dispatch("entityType/setSubContextField", [
                    routingParams.subContextKey,
                    entityData.json.data
                  ]);
                  return;
                }
              });
          } else {
            continue;
          }
        }
        resolve('ok');
      } else {
        await store
          .dispatch("entityType/setPageContext", {
            _entityType: null,
            _entityId: null,
            _params: null,
          })
        resolve('ok');
      }
    });

    return access;
  });

  return router;
}

export { init };
