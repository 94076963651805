const state = {
  debug: false,
}

const mutations = {
  SET_DEBUG(state, debug) {
    state.debug = debug;
  }
}

const getters = {
  
}

const actions = {
  'ui/toggleDebug'({ commit, state }) {
    console.log("toggleDebug", state.debug);
    commit('SET_DEBUG', !state.debug);
  }
}

export default {
    state,
    getters,
    mutations,
    actions
}

