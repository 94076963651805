<template>
  <v-navigation-drawer app :width="280" v-model="localDrawer">
    <div class="v-navigation-drawer__content-inner rounded-lg bg-surface">

      <v-sheet>
        <v-list-item class="nav--menu px-0">
          <div class="w-100 pr-4">
            <ul>
              <template :key="menu.code" v-for="menu in getMenu('bottom')">
                <li>
                  <router-link 
                    class="text-darktext"
                    v-if="menu.path"
                    :to="menu.path"
                  >
                    <span class="px-2 d-flex align-items">
                      {{ $t(menu.menu.label) }}
                      <component
                        :is="menu?.menu?.icon?.name"
                        :class="menu?.menu?.icon?.class"
                        :size="menu?.menu?.icon?.size"
                      />
                    </span>
                  </router-link>
                </li>
              </template>
              <li>
                <router-link :to="{ name: 'my-account' }" class="text-darktext">
                  <span class="px-2 d-flex align-items">
                    {{ $t("shared.menu.account") }}
                    <v-icon icon="mdi-account-cog" class="ml-4" size="25" />
                  </span>
                </router-link>
              </li>
              <li @click="logout">
                <a class="text-darktext">
                  <span class="px-2 d-flex align-items">
                    {{ $t("shared.menu.logout") }}
                    <ExitIcon class="ml-4" size="1.4" />
                  </span>
                </a>
              </li>

              <li @click="debug" v-if="isLocalhost">
                <a class="text-darktext">
                  <span class="px-2 d-flex align-items" :class="{'debug_actif': $store.state.ui.debug}">
                    Debug
                    <v-icon icon="mdi-bug" class="ml-4" size="25" />
                  </span>
                </a>
              </li>

            </ul>
          </div>
        </v-list-item>
      </v-sheet>

      <v-sheet class="header-menu">

        <!-- Logo -->
        <v-list-item class="px-0">
          <img src="@customer/shared/assets/img/logo.svg" class="logo pb-3" />
        </v-list-item>

        <!-- Divider -->
        <v-divider
          :thickness="1"
          class="border-opacity-25 mb-4 mt-0 pt-0"
          color="primary"
        />

        <!-- User -->
        <v-list-item class="px-0" :class="{'pb-6': !mustShowOrg}">
          <h3 class="text-on-surface text-h4">
            Bonjour,<br />{{ $store.getters.userGetProfile["full_name"] }}
          </h3>
        </v-list-item>

        <!-- Organization -->
        <v-list-item v-if="mustShowOrg" class="px-0 pb-2">
          <h4 class="text-on-surface text-h6 ">
            Connecté actuellement à <br /><b>{{ currentOrg }}</b>
          </h4>
          <v-btn @click="logout" color="primary" variant="text" class="mt-0 mb-1 pa-0">
            Changer d'organisation
          </v-btn>
        </v-list-item>

        <!-- Divider -->
        <v-divider
          v-if="mustShowOrg"
          :thickness="1"
          class="border-opacity-25 mb-2 mt-0 pt-0"
          color="primary"
        />

        <!-- Search -->
        <v-text-field
          bg-color="surface-input"
          density="compact"
          append-inner-icon="mdi-magnify"
          :label="$t('shared.menu.search')"
          class="menu-search v--search mt-0i mb-3"
        />

        <!-- Menu -->
        <v-list-item class="nav--menu px-0">
          <template :key="menu.code" v-for="menu in getMenu('top')">
            <router-link
              v-if="menu.path"
              :to="menu.path"
              v-slot="{ href, navigate, isActive, isExactActive }"
              custom
            >
              <!-- <div
                :class="[
                  'menu-item',
                  (isActive && 'router-link-active') || (isSubActive[href] && 'router-link-active'),
                  isExactActive && 'router-link-exact-active',
                ]"
              > -->
              <a
                :href="href"
                @click="navigate"
                :class="[
                  'text-darktext',
                  (isActive && 'router-link-active') ||
                    (isSubActive[href] && 'router-link-active'),
                  isExactActive && 'router-link-exact-active',
                ]"
              >
                <span class="px-2 d-flex align-center">
                  <component
                    :is="menu?.menu?.icon?.name"
                    :class="menu?.menu?.icon?.class"
                    :size="menu?.menu?.icon?.size"
                  />
                  {{ $t(menu.menu.label) }}</span
                >
              </a>
            </router-link>

            <a
              v-if="menu.action"
              @click="$store.dispatch(menu.action, menu.params)"
              ><span class="px-2 d-flex align-center">
                <component
                  :is="menu?.menu?.icon?.name"
                  :class="menu?.menu?.icon?.class"
                  :size="menu?.menu?.icon?.size || '1.4'"
                />
                {{ $t(menu.menu.label) }}
              </span>
            </a>
          </template>
        </v-list-item>
      </v-sheet>
    </div>
  </v-navigation-drawer>
</template>
<style>
.v-navigation-drawer {
  background-color: var(--v-theme-primary);
  border: none;
  position: fixed !important;

  .v-navigation-drawer__content {
    padding: 1.5rem 0.75rem 1.5rem 1.5rem;
    .v-navigation-drawer__content-inner {
      display: flex;
      justify-content: space-between;
      flex-direction: column-reverse;
      height: 100%;
    }
  }
}

.change-org {
  cursor: pointer;
}
</style>
<script src="./Menu.js"></script>

<style scoped>
.debug_actif {
  color: red;
}
</style>
