import axios from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import ForEach from 'lodash/forEach'
var Collection = require('json-api-resource').Collection
var Model = require('json-api-resource').Model
import store from '@shared/store'
import user from '@shared/services/normalizers/user'
import { deserialize } from "deserialize-json-api";
import dayjs from 'dayjs'

// import dayjs from 'dayjs';
// import JsonAPI from './jsonapi';
// import { Collection } from 'json-api-resource'

const API_BASE_PATH = '/api/v1/';


export default class ApiService {

    static getApiBasePath() {
        return API_BASE_PATH;
    }

    static initApi() {
        let domain;
        if (store.getters.getEnvConfigValue('appEnv') == 'dev') {
            domain = 'https://' +store.getters.getBaseConfigValue('appName') + '.hypokrat.test';
        }
        else {
            domain = store.getters.getEnvConfigValue('boUrl');
        }
        axios.defaults.baseURL = domain;
        axios.defaults.withCredentials = true;
        axios.defaults.headers.common['X-Requested-With-App'] = store.getters.getBaseConfigValue('appName');
    }

    static async getBOPage(url, params = {}) {
        let response = await axios.get(store.getters.getEnvConfigValue('boUrl') + url, params)
        return response
    }


    static async jsonApi(object, params = {}, format = 'model') {
        try {
            let return_data = await axios.post(API_BASE_PATH + 'jsonapi/' + object, params)
            return this.onSuccess(return_data, format);
        }
        catch (error) {
            return error;
        }
    }

    static async jsonApiEntity(object, uuid, params = {}) {
        try {
            let return_data = await axios.post(API_BASE_PATH + 'jsonapi/' + object + '/' + uuid, params)
            return this.onSuccess(return_data);
        }
        catch (error) {
            return error;
        }
    }

    static async restPost(path, data, _options = {}) {
        let csrf_token = localStorage.getItem('csrf');
        let options = {
            headers: {
                'X-CSRF-Token': csrf_token
            }
        };

        options = { ...options, ..._options };

        data = this.preProcessData(data)
        return new Promise((resolve, reject) => {
            axios.post(API_BASE_PATH + path, data, options)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return this.onError(reject, error);
            });
        });
    }

    static async restPatch(path, data, _options = {}) {
        let csrf_token = localStorage.getItem('csrf');
        let options = {
            headers: {
                'X-CSRF-Token': csrf_token
            }
        };

        options = { ...options, ..._options };

        data = this.preProcessData(data)

        return new Promise((resolve, reject) => {
            axios.patch(API_BASE_PATH + path, data, options)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return this.onError(reject, error);
            });
        });
    }

    static async restGet(path, uuid, _options = {}) {
        return new Promise((resolve, reject) => {
            axios.get(API_BASE_PATH + path + '/' + uuid, _options)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return this.onError(reject, error);
            });
        });
    }

    static async restDelete(path, _options = {}) {
        let csrf_token = localStorage.getItem('csrf');
        let options = {
            headers: {
                'X-CSRF-Token': csrf_token
            }
        };

        options = { ...options, ..._options };

        return new Promise((resolve, reject) => {
            axios.delete(API_BASE_PATH + path, options)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return this.onError(reject, error);
            });
        });
    }


    static preProcessData(data) {
        let _data = cloneDeep(data);

        if (Array.isArray(_data)) {
            _data = _data.map((item) => {
                return this.convertDatesToUTC(item);
            });
        } else {
            _data = this.convertDatesToUTC(_data);
        }

        return _data;
    }

    static convertDatesToUTC(object) {
        const _data = Object.assign({}, object);
        // If any of this.formData is in format YYYY-MM-DDTHH:MM, add the local timezone info
        Object.keys(_data).forEach((key) => {
            if (typeof _data[key] === 'string' && _data[key].match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?/)) {
                let date = _data[key] + store.getters.userGetTimezone;
                _data[key] = dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss');
            }
            if (typeof _data[key] === 'string' && _data[key].match(/^\d{4}-\d{2}-\d{2}$/)) {
                // Use the user timezone to add the time to the date
                let date = _data[key]+'T00:00:00' + store.getters.userGetTimezone;
                _data[key] = dayjs(date).utc();
                // Add or substract hours relative to the user timezone
                let hoursToAdd = store.getters.userGetTimezone.split(':')[0];
                _data[key] = _data[key].add(hoursToAdd, 'hour').format('YYYY-MM-DDTHH:mm:ss');
            }
        });
      
        return _data;
    }

    static async get(uri, _options = {}) {
        
        let id = _options?.id ? _options.id : false;
        let options = {}

        if (_options.params) options.params = _options.params
        if (_options.signal) options.signal = _options.signal

        let return_data = await axios.get(uri, options)
        return this.onSuccess(return_data);
    }

    static async post(uri, data, _options = {}) {
        return new Promise((resolve, reject) => {
            axios.post(uri, data, _options)
            .then((response) => {
                return resolve(response);
            })
            .catch((error) => {
                return reject(error);
            });
        });
    }

    static onSuccess(return_data, format = 'model') {
        if (return_data?.data?.jsonapi) {
            if (Array.isArray(return_data.data.data)) {
                let links = return_data.data.links;
                let return_data_origine = cloneDeep(return_data)

                //AS--> Delete attributes keys that they are in relationships keys
                return_data_origine.data.data     = this.cleanDataRelationshipsKeys(return_data_origine.data.data)
                return_data_origine.data.included = this.cleanDataRelationshipsKeys(return_data_origine.data.included)

                return_data = {
                    model: new Collection(return_data.data),
                    json: deserialize(return_data_origine.data)
                }
                return_data.links = links

                if(format == 'json'){
                    return_data = {
                        data: return_data_origine.data.data.length ? return_data.model.get(Object.keys(return_data_origine.data.data[0].attributes)) : [],
                        count: return_data_origine.data?.meta?.count ? return_data_origine.data.meta.count : null,
                        nbPages: return_data_origine.data?.meta?.nbPages ? return_data_origine.data.meta.nbPages : null,
                        page: return_data_origine.data?.meta?.page ? return_data_origine.data.meta.page : null,
                    }
                }
            } else {
                let model = new Model(return_data.data)

                return_data.data.data     = this.cleanDataRelationshipsKeys(return_data.data.data)
                return_data.data.included = this.cleanDataRelationshipsKeys(return_data.data.included)
                return_data = {
                    model: model,
                    json: deserialize(return_data.data)
                }
            }
        }

        return Promise.resolve(return_data)
    }

    static onError(rejectFunc, return_data) {
        console.log('error data', return_data);
        // Check if return_data is an axios error, and is a 403
        if (return_data?.response?.status === 403) {
            store.dispatch('auth/setForcedLogout', true);
            return rejectFunc(return_data);
        }
        else {
            if (return_data?.response) return_data = return_data.response
            if (return_data?.data?.jsonapi) {
                return_data = return_data.data.errors
            }
    
            return rejectFunc(return_data)
        }
    }

    //AS--> Delete attributes keys that they are in relationships keys
    static cleanDataRelationshipsKeys(datas){
        let wasArray = true

        if(!Array.isArray(datas)){
            datas = [datas]
            wasArray = false
        }

        datas.forEach((data, k) => {
            if(data.relationships && data.attributes){
                let relationships_keys = Object.keys(data.relationships)
                ForEach(data.attributes, function(attribute_value, attribute_key){
                    if(relationships_keys.includes(attribute_key)){
                        delete data.attributes[attribute_key]
                    }
                })
            }
        })

        return wasArray ? datas : datas[0]
    }
}