// Librairies principales
import { createApp } from 'vue'
import App from './App.vue'
import store from '@shared/store'
import api from '@shared/services/api'
import i18n from '@shared/i18n'
import * as Router from '@shared/router'

import Layout from '@admin/Layout.vue'
import createMyVuetify from './plugins/vuetify'

import HighchartsVue from 'highcharts-vue';

import VueSimpleSVG from 'vue3-simple-svg'


import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
// Note : I'm experimenting a lot with Vuetify, so this is a mess, yeah ....
// We will clean this later, it's no big deal


// Styles
import '@shared/assets/scss/main.scss' // <= CSS partagé entre toutes les apps
import '@admin/assets/scss/main.scss' // <= CSS par défaut de l'app admin
import '@customer/app-admin/assets/scss/main.scss' // <= CSS surchargé pour le client, pour l'app admin


// Imports de composants un peu en vrac (mais en async), peut être amélio aussi (?)
import sharedComponents from '@shared/components/imports.js'
import customerSharedComponents from '@customer/shared/components/imports.js'
import appComponents from '@admin/components/imports.js'
import appCustomerComponents from '@customer/app-admin/components/imports.js'


// Wrap the entire initialization process in an async function
async function initializeApp() {
    try {
        await store.dispatch('appp/initConfig', {
            appName: 'admin',
            targetUserType: 'staff',
        });

        // Initialisation de l'API (config de Axios, withCredentials, etc)
        api.initApi();

        // Chargement de la partie BO de la conf (routing, etc)
        await store.dispatch('app/initBOConfig');

        await store.dispatch('app/initI18n');

        const vuetify = createMyVuetify(store);

        const app = createApp(App);

        const router = Router.init(Layout);
        
        // Chargement des composants hypokrat importés.
        sharedComponents.import(app);
        customerSharedComponents.import(app);
        appComponents.import(app);
        appCustomerComponents.import(app);

        // Boum !
        app.use(store).use(router).use(i18n).use(vuetify).use(VueTelInput).use(HighchartsVue).use(VueSimpleSVG).mount('#app');

        // Register components for later use
        await store.dispatch('app/registerComponents', app._context.components);
    } catch (err) {
        console.error("Error during app initialization:", err);
        // Show error in the page
        const errorElement = document.createElement('div');
        errorElement.textContent = 'Une erreur est survenue lors du chargement de la configuration ...';
        document.body.appendChild(errorElement);
    }
}

// Call the initialization function
initializeApp();