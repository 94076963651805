import conditionsBlock from "@shared/mixins/conditions_block";
import entity_type from "@shared/mixins/entity_type";
import camelCase from "lodash/camelCase";
import upperFirst from "lodash/upperFirst";

export default {
  mixins: [conditionsBlock, entity_type],
  data() {
    return {
      debug_hover: false,
    };
  },
  props: {
    elem: {
      type: Object,
      default: () => ({}),
    },
    context: {
      type: String,
      default: undefined,
    }
  },
  methods: {
    debugHover() {
      this.debug_hover = true;
    },
    debugHoverLeave() {
      this.debug_hover = false;
    },
    debugModuleName(module) {
      let _module = upperFirst(camelCase(module.split('.').pop()))
      let parts = module.split('.')
      parts[parts.length - 1] = _module
      return parts.join('.')
    },
    copyContent(content) {
      console.log(content);
    }
  },
  computed: {
    mustShowComponent() {
      if ('conditionsBlock' in this.elem.element) {
        return this.getConditionsBlockResult(this.elem.element.conditionsBlock);
      }

      return true;
    }
  }
};