export default {
    watch: {
        "$store.state.auth.forced_logout": function(newVal, oldVal) {
            if (newVal) {
                this.$store.dispatch("auth/logout").then(() => {
                    this.$router.push("/accounts");
                });
            }
        }
    }
}